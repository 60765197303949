.footer {
  background-color: #171d22;
  color: #fff;
  padding: 20px;
  text-align: center;
  margin-left: 10px;
  margin-right: 15px;
  border-radius: 15px;
}

.footer-content {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-links a {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
}

.disclaimer {
  margin-top: 10px;
}

.disclaimer:before {
  content: "*";
  display: inline;
  color: red;
  font-weight: bold;
  margin-right: 5px;
}

.copyright {
  margin-top: 10px;
}

.social-icon {
  background-color: #2a353e;
  padding: 15px;
  border-radius: 25px;
  height: 20px;
  width: 20px;
}

.social-icon:hover {
  background-color: #3f4042;
}

@media only screen and (max-width: 768px) {
  .footer {
    background-color: #171d22;
    color: #fff;
    padding: 20px;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 15px;
  }
}
