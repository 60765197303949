.leaderboard-pos {
  height: 200px;
  background-color: #171d22;
  border-radius: 0px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
}

.token-address {
  height: 22px;
  border-radius: 15px 15px 0px 0px;
  width: 100%;
  background-color: #2a353e;
  color: white;
  font-weight: bold;
  align-items: center;
}

.leaderboard-note {
  height: 40px;
  border-radius: 0px 0px 15px 15px;
  width: 100%;
  background-color: #2a353e;
  font-size: 12px;
  color: rgb(149, 149, 149);
  align-items: center;
}

.note-item {
  margin-left: 15px;
  margin-right: 15px;
}

.leaderboard-note item {
  margin-left: 15px;
  margin-right: 15px;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 10px 0;
}

.buys-pos {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.buy-item {
  overflow-y: hidden;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 25px;
  margin-right: 25px;
}

.buy-item p {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  gap: 15px;
}

.sells-pos {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.sell-item {
  overflow-y: hidden;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 25px;
  margin-right: 25px;
}

.sell-item p {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  gap: 15px;
}

.link-to-address {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  text-decoration-line: none;
  margin-right: 5px;
}

.bsclogo {
  height: 18px;
  width: 18px;
}

.count-number {
  font-weight: bold;
}

.white-bg {
  background-color: #1eff0025;
}

.black-bg {
  background-color: #1eff0012;
}

.sell-white-bg {
  background-color: #ff00002e;
}

.sell-black-bg {
  background-color: #ff000015;
}

.leaderboard-title-container {
    margin: 5px 5px 5px 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.leaderboard-title {
  color: #fff;
}
