.disclaimer-button {
  background-color: transparent;
  color: #fff;
  font-weight: bold;
  border: none;
  cursor: pointer;
  display: inline;
  padding: 0;
  margin-left: 2px;
  margin-right: 2px;
}

.ReactModal__Overlay {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(7, 0, 0, 0.267) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 999 !important;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.ReactModal__Content {
  position: relative !important;
  background-color: #2a353e !important;
  padding: 24px !important;
  max-width: 400px !important;
  width: 90% !important;
  border-radius: 8px !important;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15) !important;
}

.ReactModal__Content .modal-close {
  position: absolute !important;
  top: 12px !important;
  right: 12px !important;
  background: none !important;
  border: none !important;
  cursor: pointer !important;
  font-size: 20px !important;
  color: #000000 !important;
}

.ReactModal__Content h2 {
  margin-top: 0 !important;
  margin-bottom: 16px !important;
  font-size: 20px !important;
  color: #ffffff !important;
}

.ReactModal__Content p {
  margin-bottom: 12px !important;
  font-size: 16px !important;
  color: #c2c4c7 !important;
}

.ReactModal__Content button {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  padding: 10px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-align: center !important;
  color: #ffffff !important;
  background-color: #6c757d !important;
  border: none !important;
  border-radius: 4px !important;
  cursor: pointer !important;
}

.ReactModal__Content button:hover {
  background-color: #4e555e !important;
}

.ReactModal__Content .metamask-button,
.ReactModal__Content .walletconnect-button {
  display: flex !important;
  align-items: center !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #ffffff !important;
  border: none !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
}

.ReactModal__Content .metamask-button img,
.ReactModal__Content .walletconnect-button img {
  width: 75%;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .ReactModal__Content {
    position: relative !important;
    background-color: #2a353e !important;
    padding: 24px !important;
    max-width: 400px !important;
    width: 90% !important;
    border-radius: 8px !important;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15) !important;
    margin-left: 15px;
     margin-right: 15px;
  }
}
