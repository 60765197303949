.wallet {
  height: auto;
  background-color: #171d22;
  border-radius: 0px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15%;
  padding-right: 15%;
}

.wallet-lable {
  width: 100%;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.wallet-address {
  height: 22px;
  border-radius: 15px 15px 0px 0px;
  width: 100%;
  background-color: #2a353e;
  color: white;
  font-weight: bold;
  align-items: center;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 10px 0;
}

.note {
  height: 34px;
  border-radius: 0px 0px 15px 15px;
  width: 100%;
  background-color: #2a353e;
  font-size: 12px;
  color: rgb(149, 149, 149);
  align-items: center;
  margin-bottom: 10px;
}

.note a {
  color: white;
  font-weight: bold;
  text-decoration-line: none;
  margin: 0px 2px 0px 2px;
}

.item-title {
  font-size: 16px;
  font-weight: bold;
}

.note .item {
  color: rgb(169, 169, 169);
  text-decoration-line: none;
  margin: 0px 2px 0px 2px;
  font-size: 12px;
}

@media only screen and (max-width: 1250px) {
.wallet {
  height: auto;
  background-color: #171d22;
  border-radius: 0px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10%;
  padding-right: 10%;
}
}

@media only screen and (max-width: 1100px) {
.item-title{
   font-size: 12px;
    font-weight: bold;
}
}

@media only screen and (max-width: 900px) {
  .wallet {
    height: auto;
    background-color: #171d22;
    border-radius: 0px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15%;
    padding-right: 15%;
  }

  .item-title {
    font-size: 12px;
    font-weight: bold;
  }

  .item {
    font-size: 12px;
  }
}
