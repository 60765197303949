.App-header {
  background-color: #171d22;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 30px;
}

.logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.logo img {
  width: 25px;
  height: 100%;
  margin-right: 10px;
}



.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

@media only screen and (max-width: 768px) {
  .logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 10px;
}

.logo img {
  width: 25px;
  height: 100%;
  margin-right: 10px;
}
}
