.main-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  background: #0b1217;
}

.chart {
  width: 100%;
  margin: 10px 10px 10px 5px;
}

.info {
  width: 60%;
  display: flex;
  flex-direction: column;
  margin: 10px 5px 10px 10px;
}

.prices {
  height: 50px;
  background-color: #2a353e;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 15%;
  padding-right: 15%;
  margin-bottom: 10px;
}

.small-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.small-logo img {
  height: 20px;
  width: 20px;
}

.token-title {
  color: white;
  font-size: 18px;
  font-weight: bold;
}

@media only screen and (max-width: 1300px) {
   .token-title {
    color: white;
    font-size: 15px;
    font-weight: bold;
  }
}


@media only screen and (max-width: 1200px) {
   .token-title {
    color: white;
    font-size: 14px;
    font-weight: bold;
  }
}

@media only screen and (max-width: 950px) {
  .main-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #0b1217;
    margin-left: 5px;
    margin-right: 5px;
  }

  .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0px 0px 0px 0px;
  }

  .chart {
    height: 750px;
    margin: 10px 0px 0px 0px;
  }

  .prices {
    height: 50px;
    background-color: #2a353e;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 15%;
    padding-right: 15%;
    margin-bottom: 10px;
  }

  .token-title {
    color: white;
    font-size: 13px;
    font-weight: bold;
  }
}
